/**
* Generated automatically at built-time (2024-12-03T08:04:47.828Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-weinkuehlschrank",templateKey: "sites/70-c2f414c5-e0b3-4142-8398-499d2771bfa2"};